
var target;
var control;
import Swiper from 'Swiper';
import 'swiper/css/swiper.min.css';

$(document).ready(function () {
  initSwiperPet();
});

function viewportIsMobile(width) {
  return (width < 1025);
}
$(function () {
  $('.tab-wrapper').each(function (index, value) {
    $(this).children().closest('.tab').find('.tab-name').first().trigger('click');
  });
});

function activeTab(obj) {
  $(obj).closest('.tab').find('li').removeClass('active');
  $(obj).addClass('active');
  var id = $(obj).find('a').attr('href');
  $(obj).closest('.tab-wrapper').find('.tab-item').hide();
  $(obj).closest('.tab-wrapper').find(id).show();
  $(obj).closest('.tab-wrapper').find('.tab-wrapper').each(function (index, value) {
    $(this).children().closest('.tab').find('.tab-name').first().trigger('click');
  });
}

$('.tab li').click(function () {
  activeTab(this);
  return false;
});


window.initSwiperPet = initSwiperPet;
function initSwiperPet() {
  if ($('#swiperPet').length > 0) {
    var galleryTop = new Swiper('#swiperPet', {
      grabCursor: false,
      simulateTouch: false,
      // scrollbar: {
      //   el: '.swiper-scrollbar',
      // },
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
      preloadImages: false,
      centeredSlides: false,
      loop: true,
      loopedSlides: 3,
      lazy: true,
      // thumbs: {
      //   swiper: galleryThumbs,
      // },
      pagination: {
        el: '.swiper-pagination',
      },
      // speed: 0,
      // autoplay: {
      //   delay: 5000,
      //   waitForTransition: false,
      //   disableOnInteraction: false,
      // },
      // slidesPerView: 'auto',
      // autoplayDisableOnInteraction: false,
      breakpoints: {
        375: {
          slidesPerView: 1,
          spaceBetween: 50
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 50
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 50
        },
      }
    });
    var galleryThumbs = new Swiper('#paginationPet', {
      // scrollbar: {
      //   el: '.swiper-scrollbar',
      // },
      // freeMode: true,
      // watchSlidesVisibility: true,
      // watchSlidesProgress: true,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      observer: true,
      observeParents: true,
      slideToClickedSlide: true,
      speed: 700,
      preloadImages: false,
      centeredSlides: true,
      loop: true,
      loopedSlides: 3,
      lazy: true,
      navigation: {
        nextEl: '.listclass .swiper-button-icon-next',
        prevEl: '.listclass .swiper-button-icon-prev',
      },
      breakpoints: {
        375: {
          slidesPerView: 5,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 5,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 20
        },
      }
    });
    galleryTop.controller.control = galleryThumbs;
    galleryThumbs.controller.control = galleryTop;
    // galleryTop.params.control = galleryThumbs;
    // galleryThumbs.params.control = galleryTop;
  }
  if ($('.swiper-skill').length > 0) {
    var swiper = new Swiper('.swiper-skill', {
      slidesPerView: 4,
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-skill-next',
        prevEl: '.swiper-button-skill-prev',
      },
    });
  }
}