import "./components/_scaleRoot";
$(".inner:not(.guide__fixed-background .inner)").scaleRoot({
    scaleMode: (viewportIsMobile($("body").outerWidth())) ? 'containWidth' : 'cover'
});
$(".guide__fixed-background .inner").scaleRoot({
    scaleMode: (viewportIsMobile($("body").outerWidth())) ? 'containWidth' : 'cover'
});
// $(".inner:not(.detect-iframe > .outer > #scrollContent, .popup-sn2020 .inner)").scaleRoot({
//     scaleMode: (viewportIsMobile($("body").outerWidth())) ? 'containWidth' : 'cover'
// });



function viewportIsMobile(width) {
    return (width < 1025);
}

import "./components/_lightbox";



import MobileDetect from "mobile-detect"

var md = new MobileDetect(window.navigator.userAgent);


if ($(".getLink").length > 0) {

    switch (md.os()) {
        case 'AndroidOS':
            $(".getLink").addClass("open-download-and");
            $(".open-download-and").click(function (e) {
                e.preventDefault();

                $(".popup--download-android").addClass("active");
            });
            break;
        case 'iOS':
            $(".getLink").attr("href", $(".getLink-ios").attr("href"));
            break;
        default:
    }

}
$(document).ready(function () {
    init_stickybar();
    // textDecorChar();
    // if (isMobile.any()) {
    //     $('#floating .nav-top-mb').removeClass('nav-top-promotion');
    // } else {
    //     $('#floating .nav-top-promotion').removeClass('nav-top-mb');
    // }
    $("#navigation li").hover(
        function (event) {
            $(this).find(".submenu").show();
        },
        function (event) {
            $(this).find(".submenu").hide();
        }
    );
    // if (!isMobile.any()) {
    //     $('#manhhoa').on('mousewheel', function (e) {
    //         if (e.originalEvent.wheelDelta > 0 || e.originalEvent.detail < 0) {
    //             if($(window).scrollTop() == 0){
    //                 $('.guide__fixed-background').css("top", "250px");
    //             }
    //             console.log($(window).scrollTop())
    //             // Scroll up
    //         } else {
    //             // Scroll down
    //             $('.guide__fixed-background').css("top", "0");
    //         }
    //     });
    // }
});

$('.homepage').bind('mousewheel', function (e) {

    /* Check the location of each desired element */
    $('.fade-up').each(function (i) {

        var bottom_of_object = $(this).offset().top + $(this).outerHeight();
        var bottom_of_window = $(window).scrollTop() + $(window).height();
        /* If the object is completely visible in the window, fade it in */
        if (bottom_of_window > bottom_of_object - 300) {
            $(this).addClass('active')
        }

    });

});

var isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Desktop: function () {
        return navigator.userAgent.match(/Windows NT/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};
var IsOpen = false;
$(".opennav").on("click", function (e) {
    e.preventDefault();
    if (!IsOpen) {
        $(".sub-nav-mb").addClass("Open");
        $(".opennav").addClass("toclose is-active");
        IsOpen = true;
    }
    else {
        $(".sub-nav-mb").removeClass("Open");
        $(".opennav").removeClass("toclose is-active");
        IsOpen = false;
    }
});




function init_stickybar() {
    if ($('.aside-right').length > 0) {
        $(".aside-right .bt-control").click(function () {
            if ($(this).hasClass("close")) {
                $(".aside-right").addClass('closeAsideright');
                $('.bt-control').removeClass("close").addClass('open');
            } else {
                $(".aside-right").removeClass('closeAsideright');
                $('.bt-control').removeClass("open").addClass("close");
            }
            return false;
        })
        if ($(window).outerWidth() < 1600) {
            $(".aside-right .bt-control").trigger("click");
        }
    }
}

function textDecorChar() {
    if (!isMobile.any()) {
        if ($('#swiperChar .title-char').length > 0) {
            for (var i = 0; i < $('#swiperChar .title-char').length; i++) {
                var arrayText = $('#swiperChar .title-char').eq(i).html().split(" ");
                if (arrayText.length > 0) {
                    $('#swiperChar .title-char').eq(i).html(`<span>${arrayText[0]}</span>`);
                    for (var j = 1; j < arrayText.length; j++) {
                        $('#swiperChar .title-char').eq(i).append(`${arrayText[j]} `);
                    }
                }
            }
        }
    }
}


import "./components/_content-tab";
import "./components/_language";
// import "./components/_frame-mp";
// import "./components/_control-side";
// import "./components/_progress";
// import "./components/_watchIFrame";


// danh sách hạng
// danh sách team

// xếp lại danh sách hạng : 
// $fullTeam = array();
// foreach ($listRank as $akey => $rank) {
//   $fullTeam[$rank['id']] =  $akey + 1;
// }

// foreach ($listTeam as $tkey => $team) {
//   if(array_key_exists(strval($team['id']), $fullTeam)){
//       $returnData = array(
//                         'id' => $team['id'],
//                         'logo' => $team['logo'],
//                         'totalUser' => $team['totalUser'],
//                         'teamName' => $team['teamName'], 
//                         'shortName' => $team['shortName'], 
//                         'totalPoint' => $team['totalPoint'], 
//                         'rank' => $fullTeam[$team['id']],
//                     );
//     $tours[] = $returnData;
//   }
// }