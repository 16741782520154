$.fn.scaleRoot = function (options) {
    var defaults = {
        obj: $(this),
        scaleMode: "containWidth, containHeight, cover",
        designWidth: {
            desktop: 2000,
            mobile: 768
        },
        designHeight: {
            desktop: 1000,
            mobile: 1024
        },
        popup: {
            el: '.popup',
            elBackground: '.popup__background'
        },
        setPos: {
            elLeft: '.setPosLeft',
            elRight: '.setPosRight',
            elTop: '.setPosTopMb',
            elBottom: '.setPosBottomMb',
            offsetLeft: 60,
            offsetRight: 0,
            offsetTop: 0
        }
    }
    var settings = $.extend(defaults, options);


    $(window).on("resize", function () {
        var device = {
            width: $(window).outerWidth(),
            height: $(window).outerHeight()
        };
        // console.log(device);
        var obj = {
            height: settings.obj.outerHeight()
        }


        var ratioDesign = settings.designWidth.desktop / settings.designHeight.desktop;
        var ratioActual = device.width / device.height;
        var scaleRatio;

        switch (settings.scaleMode) {
            case 'containWidth':
                if (viewportIsMobile(device.width)) {
                    scaleRatio = device.width / settings.designWidth.mobile;
                } else {
                    scaleRatio = device.width / settings.designWidth.desktop;
                }
                break;
            case 'containHeight':
                if (viewportIsMobile(device.width)) {
                    scaleRatio = device.width / settings.designHeight.mobile;
                } else {
                    scaleRatio = device.width / settings.designHeight.desktop;
                }
                break;
            case 'cover':
                if (ratioActual > ratioDesign) {
                    // console.log("scale for width")
                    // device height higher than expected => scale for width
                    if (viewportIsMobile(device.width)) {
                        scaleRatio = device.width / settings.designWidth.mobile;
                    } else {
                        scaleRatio = device.width / settings.designWidth.desktop;
                    }
                } else {
                    // console.log("scale for height")
                    // device width higher than expected => scale for height
                    if (viewportIsMobile(device.width)) {
                        scaleRatio = device.height / settings.designHeight.mobile;
                    } else {
                        scaleRatio = device.height / settings.designHeight.desktop;
                    }
                }
                break;
        }

        var scaleHeight = obj.height * scaleRatio;

        var marginLeft = 0;
        if (viewportIsMobile(device.width)) {
            marginLeft = -1 * (settings.designWidth.mobile * scaleRatio - device.width) / 2;
        } else {
            marginLeft = -1 * (settings.designWidth.desktop * scaleRatio - device.width) / 2;

        }
        var actualHeight;

        var heightpopup;

        if (viewportIsMobile(device.width)) {
            actualHeight = settings.obj.height() * scaleRatio;
            // actualHeight = settings.obj.height() * scaleRatio;
            settings.obj.css({
                display: "block",
                transform: "scale(" + scaleRatio + ")",
                marginLeft: marginLeft + "px",
                height: "auto"
            });
            settings.obj.parent().css({
                height: actualHeight + "px",
                width: device.width + "px",
                overflow: "hidden"
            });
            heightpopup = screen.height / scaleRatio

            // $(".detect-iframe .popup, .detect-iframe .popup .popup__background").css({
            //     height: $('body').outerHeight()
            // });
            //$(window).outerWidth(),
            $(".fixed-bottom").css({
                height: ($(window).outerHeight()) / scaleRatio
            });
            // $(".nav-top-promotion").css({
            //     top: $('.section--2').offset().top - 70 * scaleRatio
            // });
        }
        else {
            actualHeight = device.height * 1 / scaleRatio;
            settings.obj.css({
                display: "block",
                transform: "scale(" + scaleRatio + ")",
                marginLeft: marginLeft + "px",
                height: actualHeight + "px"
            });
            settings.obj.parent().css({
                height: "100%",
                width: device.width + "px",
                overflow: "hidden"
            });

            heightpopup = $(window).height() / scaleRatio;

            // $(".popup-sn2020 .popup__background").css({
            //     height: $('body').outerHeight()
            // });

        }

        var styleLightboxApply = function (height) {
            var style = `
                <style id="lightbox-css" type="text/css">
                    ${settings.popup.el} {
                        height: ${heightpopup}px;
                    }
                    ${settings.popup.elBackground} {
                        height: ${heightpopup}px;
                    }
                </style>
            `;

            return style;
        }





        $(settings.setPos.elLeft).css({
            left: "calc(50% - " + device.width * 1 / scaleRatio + "px/2 + " + settings.setPos.offsetLeft + "px)"
        });
        $(settings.setPos.elRight).css({
            right: "calc(50% - " + device.width * 1 / scaleRatio + "px/2 + " + settings.setPos.offsetRight + "px)"
        });
        $(settings.setPos.elBottom).css({
            bottom: "0"
        });
        $(settings.setPos.elTop).css({
            top: "calc(50% - " + device.width * 1 / scaleRatio + "px/2 + " + settings.setPos.offsetTop + "px)"
        });

        // if (viewportIsMobile(device.width)) {
        //     console.log("ip");
        //     console.log(device.height - $("html").outerHeight() );

        //     // $(settings.setPos.elBottom).css({
        //     //     top: "calc("+device.height * 1 / scaleRatio+"px - 90px - "+(device.height - $("html").outerHeight() ) * 1 / scaleRatio+"px)"
        //     //     // top: 600+"px"
        //     // });
        // }

        $('head').append(styleLightboxApply(actualHeight));
        settings.obj.attr("data-scale-ratio", scaleRatio);

    }).resize();
}

function viewportIsMobile(width) {
    return (width < 1025);
}
