

// if ($(".popup__background").length > 0) {

//     $(".popup__background").each(function () {

//         $(this).on("click", function () {
//             $(this).parent().not("#test-popup").removeClass("active");
//         })
//     })
// }
$(".close-popup,.btn-close").on("click", function () {
    $(this).parents('.popup').removeClass("active");
});
var target;
$(".btn-openpopup").on("click", function () {
    target = $(this).data("target");
    $(target).addClass("active");
})

$("#test-popup .btn-confirm").on("click", function () {
    $(this).parents('.popup').removeClass("active");
});